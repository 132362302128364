import { AbstractScene, SceneSetupFacade } from '../../scenes';
import { gsap } from 'gsap';
import * as PIXI from 'pixi.js';
import { PixiPlugin } from 'gsap/PixiPlugin';

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

export default class Scene4 extends AbstractScene {
	private scene: AbstractScene;
	public name: string = 'Scene 4';
	public index = 3;

	constructor() {
		super();
	}

	setup(index: number): AbstractScene {
		const scene = SceneSetupFacade.setup(index, this);

		// Get reference to map
		const map = scene.appManager.map.sprite;

		// Set map to zoomed in to match previous scene
		gsap.set(map, {
			pixi: { scaleX: 2, scaleY: 2, positionX: -1200, positionY: -30 },
		});

		const shipsArray = [
			{
				name: 'ship1',
				x: 1320,
				y: 1200,
				destX: 1000,
				destY: 400,
				duration: 10,
				offset: 0,
			},
			{
				name: 'ship3',
				x: 1280,
				y: 1200,
				destX: 960,
				destY: 440,
				duration: 9,
				offset: 1,
			},
			{
				name: 'ship2',
				x: 1270,
				y: 1200,
				destX: 1000,
				destY: 550,
				duration: 8,
				offset: 2,
			},
		];

		const warshipTexture =
			scene.appManager.loader.resources['warship'].texture;

		const shipSprites = [];

		shipsArray.map((ship, index) => {
			const shipSprite = new PIXI.Sprite(warshipTexture);
			shipSprite.anchor.set(0.5);
			shipSprite.scale.set(0.5);
			shipSprite.x = ship.x;
			shipSprite.y = ship.y;
			shipSprite.alpha = 0;
			shipSprites.push(shipSprite);
			shipSprite.zIndex = shipsArray.length - index;
			scene.stage.addChild(shipSprite);
		});

		scene.timeline
			.to(map, {
				pixi: { scaleX: 1, scaleY: 1, positionX: 0, positionY: 0 },
				duration: 3,
			})
			.to([shipSprites], {
				alpha: 1,
				duration: 2,
			});

		shipSprites.map((ship, index) => {
			scene.timeline.to(
				ship,
				{
					pixi: {
						positionX: shipsArray[index].destX,
						positionY: shipsArray[index].destY,
					},
					duration: shipsArray[index].duration,
					ease: 'power1.out',
				},
				`<${shipsArray[index].offset}`
			);
		});

		scene.timeline.to(shipSprites, {
			duration: 3,
		});

		this.scene = scene;
		return scene;
	}
}
