import { AppManager } from '../managers';
import { Observer } from './../misc/';
import { AbstractScene } from '../scenes';
import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
import * as PIXI from 'pixi.js';
import SOUND from 'pixi-sound';
PIXI['s' + 'o' + 'u' + 'n' + 'd'] = SOUND;

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

import { SCENES } from '../constants/';

import {
	Scene1,
	Scene2,
	Scene3,
	Scene4,
	Scene5,
	Scene6,
	Scene7,
	Scene8,
	Scene9,
	Scene10,
	Scene11,
	Scene12,
	Scene13,
	Scene14,
	Scene15,
	Scene16,
	Scene17,
	Scene18,
	Scene19,
	Scene20,
	Scene21,
	Scene22,
	Scene23,
	Scene24,
	Scene25,
	Scene26,
	Scene27,
	Scene28,
	Scene29,
	Scene30,
	Scene31,
	Scene32,
	Scene33,
	Scene34,
	Scene35,
	Scene36,
	Scene37,
	Scene38,
	Scene39,
	Scene40,
	Scene41,
	Scene42,
	Scene43,
	Scene44,
	Scene45,
	Scene46,
	Scene47,
	Scene48,
	Scene49,
	Scene50,
} from '../scenes';
import { SceneNavigator } from '../navigation';

export interface Scene {
	title: string;
}

/**
 * The SceneManager class listens for Notification from
 * the SceneNavigator Class and moves the Main Timeline
 * to the appropriate point sent by the observed Subject
 * It is also responsible for loading all scenes and
 * adding them to the Main Timeline.
 */

export default class SceneManager implements Observer {
	private sceneNavigator: SceneNavigator;
	private currentScene: AbstractScene;
	private appManager: AppManager;
	public scenes: Array<AbstractScene> = [];

	private thumb: PIXI.Sprite;
	private track: PIXI.Sprite;
	private playButtonText: PIXI.Text;
	private timeDisplayText: PIXI.Text;
	private sceneText: PIXI.Text;
	private soundOffset: number = -1;

	private data: PIXI.interaction.InteractionData;
	private dragging: boolean;

	constructor(sceneNavigator: SceneNavigator, appManager: AppManager) {
		this.sceneNavigator = sceneNavigator;
		// receive notification from the SceneNavigator
		this.sceneNavigator.registerObserver(this);
		this.appManager = appManager;
		this.createSceneText();
		this.createScrubber();
		this.createPlayPause();
		this.createAllScenes();
	}

	// Notification emitted by SceneNavigator
	notify(value: any): void {
		// Payload contains the requested scene as value.scene
		if (value) {
			this.setScene(value.scene);
		}
	}

	setScene = (sceneName: string) => {
		console.log('Scene requested: ', sceneName);

		const scene = this.scenes.find((scene) => {
			return scene.getName() === sceneName;
		});

		//console.log('scene found is: ', scene);
		//console.log('The scenes created are: ', this.scenes);

		if (scene) {
			gsap.killTweensOf(scene);
			scene.init(this.appManager).setup(scene.getIndex());
			this.currentScene = scene;

			//console.log('Current Scene: ', this.currentScene.name);
			//console.log('Duration: ', this.currentScene.timeline.duration());

			this.sceneText.text = this.currentScene.name;

			// add Scene to stage
			this.appManager.sceneContainer.removeChildren(
				0,
				this.appManager.sceneContainer.children.length
			);
			// add the entire scene
			this.appManager.sceneContainer.addChild(scene.stage);

			/**
			 * Scene Event Handlers
			 */
			// Update
			this.currentScene.timeline.eventCallback('onUpdate', this.onUpdate);

			// // Complete
			// this.currentScene.timeline.eventCallback(
			// 	'onComplete',
			// 	this.onComplete
			// );

			this.currentScene.timeline.eventCallback('onStart', this.onStart);

			// Set Active State on SceneNavigator for red dots
			this.sceneNavigator.setActive(this.currentScene.name);
			//this.currentScene.timeline.seek(0);
			// rewind playhead
			this.currentScene.timeline.restart();
			// play
			this.currentScene.timeline.play();
		} else {
			console.log('SCENE NOT FOUND: ' + sceneName);
		}
	};

	createAllScenes = () => {
		this.scenes = [
			new Scene1(),
			new Scene2(),
			new Scene3(),
			new Scene4(),
			new Scene5(),
			new Scene6(),
			new Scene7(),
			new Scene8(),
			new Scene9(),
			new Scene10(),
			new Scene11(),
			new Scene12(),
			new Scene13(),
			new Scene14(),
			new Scene15(),
			new Scene16(),
			new Scene17(),
			new Scene18(),
			new Scene19(),
			new Scene20(),
			new Scene21(),
			new Scene22(),
			new Scene23(),
			new Scene24(),
			new Scene25(),
			new Scene26(),
			new Scene27(),
			new Scene28(),
			new Scene29(),
			new Scene30(),
			new Scene31(),
			new Scene32(),
			new Scene33(),
			new Scene34(),
			new Scene35(),
			new Scene36(),
			new Scene37(),
			new Scene38(),
			new Scene39(),
			new Scene40(),
			new Scene41(),
			new Scene42(),
			new Scene43(),
			new Scene44(),
			new Scene45(),
			new Scene46(),
			new Scene47(),
			new Scene48(),
			new Scene49(),
			new Scene50(),
		];

		console.log('Alls scenes created: ', this.scenes);
		//Kick off Presentation with Scene 1
		// this.setScene('Scene 1');
		// this.currentScene.timeline.play();
		// this.playButtonText.text = 'PAUSE';

		// TODO: create a check to see whether scene1 has been loaded
	};

	/**
	 * Create the Scene Controller UI
	 */

	// Scene Text Display
	createSceneText = () => {
		const sceneText = new PIXI.Text('Scene 1', {
			fontFamily: 'ACaslonPro-Regular',
			fontSize: 24,
			fill: 0x43403d,
			align: 'center',
		});

		const textDistanceFromBottom = 180;
		sceneText.anchor.set(0.5);
		sceneText.x = AppManager.logicalWidth / 2;
		sceneText.y = AppManager.logicalHeight - textDistanceFromBottom;
		sceneText.resolution = this.appManager.resolution;

		this.sceneText = sceneText;

		this.appManager.app.stage.addChild(sceneText);
	};

	// Play/Pause Button
	createPlayPause = () => {
		// Scale the Graphics twice as large to avoid pixilation
		const scale = 2;
		const playButtonContainer = new PIXI.Container();
		const buttonHeight = 24 * scale;
		const buttonWidth = 100 * scale;
		const buttonRadius = buttonHeight / 2;

		const graphics = new PIXI.Graphics();
		graphics.lineStyle(2, 0x43403d, 1);
		graphics.beginFill(0xe7dbcd);
		graphics.drawRoundedRect(0, 0, buttonWidth, buttonHeight, buttonRadius);
		graphics.endFill();

		const buttonTexture = this.appManager.app.renderer.generateTexture(
			graphics,
			PIXI.SCALE_MODES.LINEAR,
			this.appManager.resolution
		);

		const buttonSprite = new PIXI.Sprite(buttonTexture);
		buttonSprite.anchor.set(0.5);
		buttonSprite.x = Math.round(
			this.track.x - this.track.width / 2 - buttonSprite.width / 2 - 10
		);
		buttonSprite.scale.set(1 / scale); // scale the button
		buttonSprite.y = this.track.y;
		buttonSprite.interactive = true;
		buttonSprite.buttonMode = true;

		// rollover
		buttonSprite.on('mouseover', () => {
			buttonSprite.tint = 0xe6e6e6;
		});

		buttonSprite.on('mouseout', () => {
			buttonSprite.tint = 0xffffff;
		});

		buttonSprite.on('mousedown', () => {
			buttonSprite.tint = 0xe6e6e6;
		});

		buttonSprite
			.on('mousedown', this.onPlayButtonPress)
			.on('touchstart', this.onPlayButtonPress);

		graphics.clear();
		graphics.destroy();

		// The Button Text
		const playButtonText = new PIXI.Text('PLAY', {
			fontFamily: 'Arial',
			fontSize: 16,
			fill: 0x43403d,
			align: 'center',
		});
		playButtonText.resolution = this.appManager.resolution;
		playButtonText.anchor.set(0.5);
		playButtonText.x = buttonSprite.x;
		playButtonText.y = buttonSprite.y;
		this.playButtonText = playButtonText;

		playButtonContainer.addChild(buttonSprite, playButtonText);

		this.appManager.app.stage.addChild(playButtonContainer);
	};

	// Returns the next scene in the sequence
	getNextScene = (sceneName: string): AbstractScene => {
		const sceneIndex = this.getCurrentSceneIndex(sceneName);

		if (sceneIndex < this.scenes.length - 1) {
			return this.scenes[sceneIndex + 1];
		} else {
			return null;
		}
	};

	getCurrentSceneIndex = (sceneName: string): number => {
		const sceneIndex = this.scenes
			.map((scene, index) => {
				return scene.name;
			})
			.indexOf(sceneName);

		return sceneIndex;
	};

	// The Timeline Scrubber
	createScrubber = () => {
		const scrubberContainer = new PIXI.Container();
		const scale = 2;
		const scrubberHeight = 24 * scale;
		const scrubberWidth = 374 * scale;
		const scrubberRadius = scrubberHeight / 2;
		const distanceFromBottom = 134;

		const graphics = new PIXI.Graphics();
		graphics.lineStyle(2, 0x43403d, 1);
		graphics.beginFill(0x000000, 0.05);
		graphics.drawRoundedRect(
			0,
			0,
			scrubberWidth,
			scrubberHeight,
			scrubberRadius
		);
		graphics.endFill();

		const scrollTrackTexture = this.appManager.app.renderer.generateTexture(
			graphics,
			PIXI.SCALE_MODES.LINEAR,
			this.appManager.resolution
		);

		graphics.clear();

		const scrollTrackSprite = new PIXI.Sprite(scrollTrackTexture);

		scrollTrackSprite.anchor.set(0.5);
		scrollTrackSprite.x = Math.round(AppManager.logicalWidth / 2);
		scrollTrackSprite.y = Math.round(
			AppManager.logicalHeight - distanceFromBottom
		);
		scrollTrackSprite.scale.set(1 / scale);
		scrollTrackSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY;
		this.track = scrollTrackSprite;

		// Scrubber
		graphics.lineStyle(2, 0x43403d, 1);
		graphics.beginFill(0xe7dbcd);
		graphics.drawCircle(0, 0, scrubberRadius);
		graphics.endFill();

		const thumbScrollerTexture = this.appManager.app.renderer.generateTexture(
			graphics,
			PIXI.SCALE_MODES.LINEAR,
			this.appManager.resolution
		);
		const thumbScrollerSprite = new PIXI.Sprite(thumbScrollerTexture);
		graphics.destroy();
		thumbScrollerSprite.scale.set(1 / scale);
		thumbScrollerSprite.anchor.set(0.5);
		thumbScrollerSprite.x =
			scrollTrackSprite.x - scrollTrackSprite.width / 2 + scrubberRadius;
		thumbScrollerSprite.y = scrollTrackSprite.y;
		thumbScrollerSprite.buttonMode = true;
		thumbScrollerSprite.interactive = true;

		this.thumb = thumbScrollerSprite;

		thumbScrollerSprite
			.on('mousedown', this.onDragStart)
			.on('touchstart', this.onDragStart)
			.on('mouseup', this.onDragEnd)
			.on('mouseupoutside', this.onDragEnd)
			.on('touchend', this.onDragEnd)
			.on('touchendoutside', this.onDragEnd)
			.on('mousemove', this.onDragMove)
			.on('touchmove', this.onDragMove);

		// The Thumb Scroller Time Display

		// The Time Display
		const timeDisplayText = new PIXI.Text('0.00 / 0.00', {
			fontFamily: 'Arial',
			fontSize: 16,
			fill: 0x43403d,
			align: 'left',
		});
		timeDisplayText.resolution = this.appManager.resolution;
		timeDisplayText.anchor.set(0.5);
		timeDisplayText.x = Math.round(
			this.track.x + this.track.width / 2 + timeDisplayText.width / 2 + 20
		);
		timeDisplayText.y = this.track.y;
		this.timeDisplayText = timeDisplayText;

		scrubberContainer.addChild(
			scrollTrackSprite,
			thumbScrollerSprite,
			timeDisplayText
		);

		this.appManager.app.stage.addChild(scrubberContainer);
	};

	getCurrentSceneAudio(): string {
		return this.currentScene.narrationAudio;
	}

	/**
	 * Event Handlers
	 */
	// The Timeline

	onStart = () => {
		console.log('Scene Started');
		// Stop all sounds that may be playing to prevent overlap with previously playing scene
		PIXI.sound.stopAll();
		// Play the narration Audio
		if (!this.dragging) {
			PIXI.sound.play(this.currentScene.narrationAudio);
		}
	};

	onUpdate = () => {
		this.timeDisplayText.text =
			this.currentScene.timeline.time().toFixed(2) +
			' / ' +
			this.currentScene.timeline.duration().toFixed(2);

		// Move the thumb scroller according to the animation progress
		this.thumb.x =
			this.track.x -
			this.track.width / 2 +
			this.thumb.width / 2 +
			this.currentScene.timeline.progress() *
				(this.track.width - this.thumb.width);

		// The "Complete" callback event was misfiring, so we create our own
		if (this.currentScene.timeline.progress() === 1) {
			console.log('Scene Complete');
			if (
				!this.dragging &&
				this.getCurrentSceneIndex(this.currentScene.name) <
					this.scenes.length - 1
			) {
				this.setScene(this.getNextScene(this.currentScene.name).name);
			}
		}
	};

	// The PlAY/PAUSE Button
	onPlayButtonPress = (event: PIXI.interaction.InteractionEvent) => {
		// Toggle the button to reverse the pause state

		this.currentScene.timeline.paused(!this.currentScene.timeline.paused());
		// Display "PLAY" if paused and "PAUSE" if currently playing
		this.playButtonText.text = this.currentScene.timeline.paused()
			? 'PLAY'
			: 'PAUSE';
		// If the timeline is paused and we are not at the end, when pressed:
		if (
			this.currentScene.timeline.paused() &&
			this.currentScene.timeline.progress() != 1
		) {
			PIXI.sound.stopAll();
		} else if (
			// We always play audio from wherever the playhead happens to be
			// If the timeline is playing and we haven't reached the end:
			!this.currentScene.timeline.paused() &&
			this.currentScene.timeline.progress() < 1
		) {
			PIXI.sound.stopAll();
			// console.log('playing offset: ', this.soundOffset);
			PIXI.sound.play(this.currentScene.narrationAudio, {
				start: this.calculateSoundOffset(),
				end: PIXI.sound.duration(this.currentScene.narrationAudio),
			});
		} else if (this.currentScene.timeline.progress() == 1) {
			this.playButtonText.text = 'PAUSE';
			this.setScene(this.getNextScene(this.currentScene.name).name);
		}
	};
	// The Scrubber
	onDragStart = (event: PIXI.interaction.InteractionEvent) => {
		this.data = event.data;
		this.thumb.tint = 0xfffdfc;
		this.dragging = true;
		this.currentScene.timeline.paused(true);
		PIXI.sound.pause(this.currentScene.narrationAudio);
	};

	calculateSoundOffset = () => {
		const progress = this.currentScene.timeline.progress();
		const totalDuration = this.currentScene.timeline.duration();
		const audioDuration = PIXI.sound.duration(
			this.currentScene.narrationAudio
		);
		const audioProportion = audioDuration / totalDuration;
		const soundOffset = progress / audioProportion;

		return soundOffset * audioDuration;
	};

	onDragEnd = () => {
		this.thumb.tint = 0xffffff;
		this.dragging = false;
		this.data = null;
	};

	onDragMove = () => {
		if (this.dragging) {
			let newPosition = this.data.getLocalPosition(
				this.appManager.app.stage
			);

			this.currentScene.timeline.paused(true);

			const xMin = Math.round(
				this.track.x - this.track.width / 2 + this.thumb.width / 2
			);
			const xMax = Math.round(
				this.track.x + this.track.width / 2 - this.thumb.width / 2
			);

			if (newPosition.x < xMin) {
				newPosition.x = xMin;
			} else if (newPosition.x > xMax) {
				newPosition.x = xMax;
			}
			this.thumb.x = newPosition.x;

			let progress =
				(this.thumb.x - xMin) / (this.track.width - this.thumb.width);

			// Set the current scene's animation progress to that of the scroller
			this.currentScene.timeline.progress(progress).pause();
			this.playButtonText.text = 'PLAY';

			// console.log(
			// 	'progress: ',
			// 	(this.thumb.x - xMin) / (this.track.width - this.thumb.width)
			// );
		}
	};
}
