import { AbstractScene, SceneSetupFacade, Scene2 } from '../../scenes';
import { gsap } from 'gsap';
import * as PIXI from 'pixi.js';
import { PixiPlugin } from 'gsap/PixiPlugin';

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

export default class Scene3 extends AbstractScene {
	public name: string = 'Scene 3';
	public index = 2;
	constructor() {
		super();
	}
	private scene: AbstractScene;

	setup(index: number): AbstractScene {
		const scene = SceneSetupFacade.setup(index, this);

		// Get reference to main map
		const map = scene.appManager.map.sprite;

		//Set Map Zoom level and position
		gsap.set(map, {
			pixi: { scaleX: 2, scaleY: 2, positionX: -1200, positionY: -30 },
		});

		// Set Tower icons to those of previous scene
		// Load fort Texture
		const towerTexture = scene.appManager.loader.resources['tower'].texture;

		const fortArray = [
			{ name: 'Ft. George', x: 1265, y: 210 },
			{ name: 'Ft. Stirling', x: 1472, y: 253 },
			{ name: 'Ft. Putnam', x: 1610, y: 350 },
			{ name: 'Cobble Hill Ft.', x: 1447, y: 358 },
			{ name: 'Ft. Greene', x: 1538, y: 410 },
			{ name: 'Ft. Box', x: 1447, y: 437 },
			{ name: 'Ft. Defiance', x: 1232, y: 519 },
		];

		// Create fort label text style
		const textConfigDark = {
			fontFamily: 'ACaslonPro-Regular',
			fontSize: 24,
			fill: 0x000000,
			strokeThickness: 8,
			lineJoin: 'round',
			stroke: 0xe7dbcd,
			align: 'center',
		};

		const fortSprites = [];
		const fortLabels = [];

		// Instantiate each fort and set initial properties
		fortArray.map((fort) => {
			// Create fort Sprite
			const fortSprite = new PIXI.Sprite(towerTexture);
			fortSprite.anchor.set(0.5, 1);
			fortSprite.scale.set(0.8);
			fortSprite.x = fort.x;
			fortSprite.y = fort.y;
			fortSprite.alpha = 1;
			fortSprites.push(fortSprite);
			// Create the fort Label
			const fortLabel = new PIXI.Text(fort.name, textConfigDark);
			fortLabel.anchor.set(0.5, 3.5); // shift the anchor from center
			fortLabel.resolution = this.appManager.resolution;
			fortLabel.x = fort.x + fortSprite.width / 4;
			fortLabel.y = fort.y;
			fortLabels.push(fortLabel);
			// Add to Stage
			scene.stage.addChild(fortSprite, fortLabel);
		});

		// Add the cannons
		const cannon1Texture =
			scene.appManager.loader.resources['cannon1'].texture;
		const cannon2Texture =
			scene.appManager.loader.resources['cannon2'].texture;
		const cannon3Texture =
			scene.appManager.loader.resources['cannon3'].texture;

		const cannonArray = [
			{
				name: 'cannon1',
				x: 1391,
				y: 128,
				sprite: new PIXI.Sprite(cannon1Texture),
			},
			{
				name: 'cannon2',
				x: 1283,
				y: 281,
				sprite: new PIXI.Sprite(cannon2Texture),
			},
			{
				name: 'cannon3',
				x: 1346,
				y: 433,
				sprite: new PIXI.Sprite(cannon3Texture),
			},
		];

		const labelArray = [
			{ xOffset: 10, yOffset: -70 },
			{ xOffset: 0, yOffset: -40 },
			{ xOffset: 30, yOffset: 90 },
		];

		textConfigDark.fontSize = 130;

		const cannonLabels: Array<PIXI.Sprite> = [];

		const cannonSprites: Array<PIXI.Sprite> = [];

		cannonArray.map((cannon, index) => {
			cannon.sprite.x = cannon.x;
			cannon.sprite.y = cannon.y;
			cannon.sprite.alpha = 0;
			cannonSprites.push(cannon.sprite);
			const cannonLabel = new PIXI.Text('?', textConfigDark);
			cannonLabel.resolution = this.appManager.resolution;
			cannonLabel.x = cannon.x - 100 + labelArray[index].xOffset;
			cannonLabel.y = cannon.y + labelArray[index].yOffset;
			cannonLabel.alpha = 0;
			cannonLabels.push(cannonLabel);
			scene.stage.addChild(cannon.sprite, cannonLabel);
		});

		scene.timeline
			.to([fortSprites, fortLabels], {
				alpha: 0,
				duration: 0.5,
			})
			.to(
				cannonSprites,
				{
					alpha: 1,
					duration: 2,
					ease: 'power2.inOut',
					stagger: {
						from: 'start',
						each: 0.5,
						ease: 'none',
					},
				},
				'>'
			)
			.to(
				cannonLabels,
				{
					alpha: 1,
					duration: 2,
					ease: 'back',
					pixi: { scaleX: 1.2, scaleY: 1.2 },
					stagger: {
						from: 'start',
						each: 0.5,
						ease: 'none',
					},
				},
				'<0.3'
			)
			.to(cannonSprites, {
				duration: 3,
			});

		this.scene = scene;
		return scene;
	}
}
