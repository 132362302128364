import { AbstractScene, SceneSetupFacade } from '../../scenes';
import * as PIXI from 'pixi.js';

import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

import SOUND from 'pixi-sound';
PIXI['s' + 'o' + 'u' + 'n' + 'd'] = SOUND;

export default class Scene2 extends AbstractScene {
	private scene: AbstractScene;
	public name: string = 'Scene 2';
	public index = 1;

	constructor() {
		super();
	}
	playPop = () => {
		// the pop sound is already in the library from the asset manager
		PIXI.sound.play('pop');
	};

	setup(index: number): AbstractScene {
		const scene = SceneSetupFacade.setup(index, this);

		// Load fort Texture
		const towerTexture = scene.appManager.loader.resources['tower'].texture;

		const fortArray = [
			{ name: 'Ft. George', x: 1265, y: 210 },
			{ name: 'Ft. Stirling', x: 1472, y: 253 },
			{ name: 'Ft. Putnam', x: 1610, y: 350 },
			{ name: 'Cobble Hill Ft.', x: 1447, y: 358 },
			{ name: 'Ft. Greene', x: 1538, y: 410 },
			{ name: 'Ft. Box', x: 1447, y: 437 },
			{ name: 'Ft. Defiance', x: 1232, y: 519 },
		];

		// Create fort label text style
		const textConfigDark = {
			fontFamily: 'ACaslonPro-Regular',
			fontSize: 24,
			fill: 0x000000,
			strokeThickness: 8,
			lineJoin: 'round',
			stroke: 0xe7dbcd,
			align: 'center',
		};

		const fortSprites = [];
		const fortLabels = [];

		// Instantiate each fort and set initial properties
		fortArray.map((fort) => {
			// Create fort Sprite
			const fortSprite = new PIXI.Sprite(towerTexture);
			fortSprite.anchor.set(0.5, 1);
			fortSprite.scale.set(0.5);
			fortSprite.x = fort.x;
			fortSprite.y = fort.y;
			fortSprite.alpha = 0;
			fortSprites.push(fortSprite);
			// Create the fort Label
			const fortLabel = new PIXI.Text(fort.name, textConfigDark);
			fortLabel.anchor.set(0.5, 3.5); // shift the anchor from center
			fortLabel.resolution = this.appManager.resolution;
			fortLabel.x = fort.x + fortSprite.width / 4;
			fortLabel.y = fort.y;
			fortLabels.push(fortLabel);
			// Add to Stage
			scene.stage.addChild(fortSprite, fortLabel);
		});

		// Get reference to main map
		const map = scene.appManager.map.sprite;

		// ZOOM Map and center
		scene.timeline
			.to(map, {
				pixi: { scaleX: 2, scaleY: 2, positionX: -1200, positionY: -30 },
				ease: 'power2.inOut',
				duration: 3,
			})
			// Pop up forts
			.to(
				fortSprites,
				{
					pixi: { scaleX: 0.8, scaleY: 0.8 },
					alpha: 1,
					stagger: {
						from: 'start',
						each: 0.5,
						ease: 'none',
						onComplete: this.playPop,
						onReverseComplete: this.playPop,
					},
				},
				'+=0.5'
			)
			.from(
				fortLabels,
				{
					pixi: { rotation: -90, alpha: 0 },
					stagger: {
						from: 'start',
						each: 0.5,
						ease: 'none',
					},
				},
				'<0.1'
			)
			// Add null time to timeline
			.to(fortSprites, {
				duration: 3,
			});
		this.scene = scene;

		return scene;
	}
}
