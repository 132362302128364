import { AbstractScene, NarrationText } from '../scenes';
import * as PIXI from 'pixi.js';
import * as SCENES from '../constants/scenes';
import * as STYLES from '../constants/styles';

import gsap from 'gsap';

export default class SceneSetupFacade {
	public static index: number;

	public static setup = (
		index: number,
		scene: AbstractScene
	): AbstractScene => {
		SceneSetupFacade.index = index;
		scene.name = SCENES.scenes[index].title;

		const style: PIXI.TextStyle = new PIXI.TextStyle(
			STYLES.narrationTextStyle
		);
		style.fontSize = 48;
		const narrationText = new NarrationText(scene.appManager, index, style);
		const { timeline, narrationSprite } = narrationText.createNarrationText();

		const delayTime = narrationText.audioDuration;
		console.log('delay time is: ', delayTime);
		const narrationAudio = SCENES.scenes[index].audio;

		// add hold to timeline by adding blank space
		// Dummy tween that does nothing but takes time
		timeline.to(narrationSprite, {
			duration: delayTime - narrationText.duration,
		});
		// the sprite is the narration text
		scene.timeline = timeline;
		scene.narrationSprite = narrationSprite;
		scene.narrationAudio = narrationAudio;

		scene.stage.addChild(narrationSprite);

		return scene;
	};
}
