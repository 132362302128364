import { Observer, Subject } from './../misc/patterns';
import { AppManager } from '../managers';
import * as SCENES from '../constants/scenes';
import { EVENTS } from '../constants';
import * as PIXI from 'pixi.js';
import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

// Extend the Sprite Class to add some useful properties
export class SpritePlus extends PIXI.Sprite {
	private _active: boolean;
	private _title: string;
	constructor(sprite: any) {
		super(sprite);
	}
	get active(): boolean {
		return this._active;
	}

	set active(value: boolean) {
		this._active = value;
	}
	get title(): string {
		return this._title;
	}

	set title(value: string) {
		this._title = value;
	}
}

/**
 * SceneNavigator is an Observer of the AssetManager
 * It receives notification when all assets are loaded
 * It is also a subject that issues notification when a new Scnene is requested
 * CreateNavigator() is called when all assets are loaded
 */

export default class SceneNavigator implements Subject {
	private appManager: AppManager;
	private isNavigationVisible: boolean;
	private navigationDots: Array<SpritePlus>;
	private currentActiveDot: SpritePlus;
	private connectorLine: PIXI.Sprite;
	private resolution: number;
	private currentSceneTitle: string;
	private _observers: Array<Observer> = [];

	constructor(appManager: AppManager) {
		this.navigationDots = [];
		this.appManager = appManager;
		this.resolution = appManager.resolution;
	}

	// Notify Observers when a scene is requested
	registerObserver(o: Observer): void {
		this._observers.push(o);
	}
	removeObserver(o: Observer): void {
		let index = this._observers.indexOf(o);
		this._observers.splice(index, 1);
	}
	notifyObservers(): void {
		for (let observer of this._observers) {
			observer.notify({
				type: EVENTS.SCENE_REQUESTED,
				scene: this.currentSceneTitle,
			});
		}
	}

	// The SceneNavigator also listens for AssetManager Notification when assets have been loaded

	createNavigation = () => {
		console.log('Creating Navigation...');
		//console.log('Resolution: ', this.resolution);

		const navContainer = new PIXI.Container();
		const scenes = SCENES.scenes;
		const distanceFromBottom = 80; // distance from bottom edge
		// The container for the navigaton
		const Sprite = PIXI.Sprite;

		const id = this.appManager.loader.resources.mapElements.textures;
		//console.log('[Loader]', id);

		// The Ships's Wheel Icon
		const shipWheel = new Sprite(id['ship_wheel.png']);
		shipWheel.anchor.set(0.5);
		shipWheel.x = 100;
		shipWheel.scale.set(0.6);
		shipWheel.y = AppManager.logicalHeight - distanceFromBottom;
		shipWheel.interactive = true;
		shipWheel.buttonMode = true;
		shipWheel.on('mouseover', (event: PIXI.interaction.InteractionEvent) => {
			let sec = 0.3;
			gsap.to(shipWheel, sec, {
				pixi: { scaleX: 0.8, scaleY: 0.8, rotation: 30 },
				ease: 'back',
			});
		});
		shipWheel.on('mouseout', (event: PIXI.interaction.InteractionEvent) => {
			let sec = 0.3;
			gsap.to(shipWheel, {
				duration: sec,
				pixi: { scaleX: 0.6, scaleY: 0.6, rotation: -30 },
				ease: 'back',
			});
		});

		shipWheel.on('click', () => {
			this.toggleNavigation(shipWheel);
		});

		navContainer.addChild(shipWheel);

		// make twice as large as we need it to allow for scaling without blur
		const circleRadius = 24; // the radius of the navigation dot
		const innerRadius = circleRadius - circleRadius * 0.3; //
		const circleDiam = 2 * circleRadius;
		let spacing = 10; // spacing between dots
		const totalWidth =
			shipWheel.width +
			spacing +
			scenes.length * circleRadius +
			(scenes.length - 1) * spacing; // the total width of the navbar
		const margin = (AppManager.logicalWidth - totalWidth) / 2; // the space left on either side
		const lineThickness = 8; // connecting line thickness

		const graphics = new PIXI.Graphics();
		// The Connecting line
		graphics.beginFill(0x43403d);
		graphics.drawRect(
			margin + shipWheel.width + spacing,
			AppManager.logicalHeight - distanceFromBottom - lineThickness / 2,
			totalWidth - (shipWheel.width + spacing + circleRadius),
			lineThickness
		);
		graphics.endFill();
		let connectorLineTexture = this.appManager.app.renderer.generateTexture(
			graphics,
			PIXI.SCALE_MODES.LINEAR,
			this.resolution
		);
		graphics.destroy();
		let connectorSprite = new PIXI.Sprite(connectorLineTexture);
		//connectorSprite.anchor.set(0.5);
		connectorSprite.x = margin + shipWheel.width + spacing; //AppManager.logicalWidth / 2 + circleRadius;
		connectorSprite.y =
			AppManager.logicalHeight -
			distanceFromBottom -
			connectorSprite.height / 2;
		this.connectorLine = connectorSprite;
		navContainer.addChild(connectorSprite);

		// Dots
		let x: number,
			y: number = circleRadius;
		const dot = new PIXI.Graphics();
		// outer dot
		dot.lineStyle(1, 0x43403d, 1);
		dot.beginFill(0xe7dbcd);
		dot.drawCircle(x, y, circleRadius);
		dot.endFill();
		// inner dot
		dot.lineStyle(1, 0x43403d, 1);
		dot.drawCircle(x, y, innerRadius);

		const stateDot = new PIXI.Graphics();
		// inner dot for rollover state
		stateDot.lineStyle(1, 0x43403d, 1);
		stateDot.beginFill(0x43403d);
		stateDot.drawCircle(x, y, innerRadius);
		stateDot.endFill();

		const activeDot = new PIXI.Graphics();
		// red dot for active state
		activeDot.lineStyle(1, 0x43403d, 1);
		activeDot.beginFill(0xa12f27);
		activeDot.drawCircle(x, y, innerRadius);
		activeDot.endFill();

		// The base texture for the sprite
		let outerDotTexture = this.appManager.app.renderer.generateTexture(
			dot,
			PIXI.SCALE_MODES.LINEAR,
			this.resolution
		);
		dot.destroy();

		let innerDotTexture = this.appManager.app.renderer.generateTexture(
			stateDot,
			PIXI.SCALE_MODES.LINEAR,
			this.resolution
		);
		stateDot.destroy();

		let innerDotActiveTexture = this.appManager.app.renderer.generateTexture(
			activeDot,
			PIXI.SCALE_MODES.LINEAR,
			this.resolution
		);
		activeDot.destroy();

		// Put the text labels on dots and mouse events
		scenes.map((scene, i) => {
			// set layout coords
			let x =
				shipWheel.width + spacing + margin + i * (circleRadius + spacing);
			let y = AppManager.logicalHeight - distanceFromBottom;
			// create outer dot sprite
			let sprite = new SpritePlus(outerDotTexture);
			sprite.anchor.set(0.5);
			// create inner dot sprite
			let innerDot = new PIXI.Sprite(innerDotTexture);
			innerDot.anchor.set(0.5);
			innerDot.alpha = 0; // begin with state off
			let activeDot = new PIXI.Sprite(innerDotActiveTexture);
			activeDot.anchor.set(0.5);
			activeDot.alpha = 0; // being with state off

			// create dark text object
			let textConfigDark = {
				fontFamily: 'Arial',
				fontSize: 18,
				fill: 0x43403d,
				align: 'center',
			};

			let text = new PIXI.Text((i + 1).toString(10), textConfigDark);
			text.anchor.set(0.5);
			text.resolution = this.resolution;

			let dotTextTextureDark = this.appManager.app.renderer.generateTexture(
				text,
				PIXI.SCALE_MODES.LINEAR,
				this.resolution
			);
			let numberSpriteDark = new PIXI.Sprite(dotTextTextureDark);
			text.destroy();

			numberSpriteDark.anchor.set(0.5);
			numberSpriteDark.x, (sprite.x = x);
			numberSpriteDark.y, (sprite.y = y);

			// Create Light Text Object
			let textConfigLight = {
				fontFamily: 'Arial',
				fontSize: 18,
				fill: 0xffffff,
				align: 'center',
			};
			let text2 = new PIXI.Text((i + 1).toString(10), textConfigLight);
			text2.anchor.set(0.5);
			text2.resolution = this.resolution;

			let dotTextTextureLight = this.appManager.app.renderer.generateTexture(
				text2,
				PIXI.SCALE_MODES.LINEAR,
				this.resolution
			);
			text2.destroy();
			let numberSpriteLight = new PIXI.Sprite(dotTextTextureLight);

			numberSpriteLight.anchor.set(0.5);
			numberSpriteLight.x, (sprite.x = x);
			numberSpriteLight.y, (sprite.y = y);
			numberSpriteLight.alpha = 0;

			sprite.addChild(
				innerDot,
				activeDot,
				numberSpriteDark,
				numberSpriteLight
			);
			sprite.scale.set(0.5);
			sprite.title = scene.title;
			sprite.interactive = true;
			sprite.buttonMode = true;

			sprite.on('pointerup', (event: PIXI.interaction.InteractionEvent) => {
				//console.log((event.target as SpritePlus).title);
				let sceneReqTitle = (event.target as SpritePlus).title;

				// this.sceneTextRef.text = sceneReqTitle;
				this.currentSceneTitle = sceneReqTitle;

				this.setActive(sprite.title);
				this.notifyObservers();
			});

			sprite.on('mouseover', (event: PIXI.interaction.InteractionEvent) => {
				let sec = 0.3;
				// Scale up
				gsap.to(sprite, {
					pixi: { scaleX: 1, scaleY: 1 },
					ease: 'back',
					duration: sec,
				});
				// Fade up state dot
				gsap.to(sprite.children[0], sec, {
					alpha: 0.9,
				});
				gsap.to(sprite.children[3], sec, {
					alpha: 0.9,
				});
			});
			sprite.on('mouseout', (event: PIXI.interaction.InteractionEvent) => {
				let sec = 0.3;
				if (!sprite.active) {
					// Shrink back down
					gsap.to(sprite, {
						pixi: { scaleX: 0.5, scaleY: 0.5 },
						duration: sec,
					});
					// Fade out state dot
					gsap.to(sprite.children[0], sec, {
						alpha: 0,
					});
					// Change text to dark
					gsap.to(sprite.children[3], sec, {
						alpha: 0,
					});
				}
			});

			this.navigationDots.push(sprite);
			navContainer.addChild(sprite);
			this.isNavigationVisible = true;
		});

		return navContainer;
	};

	setActive = (scneneName: string) => {
		//console.log('Set active called with: ', sprite);
		let sec = 0.3;
		// deactivate current active
		if (this.currentActiveDot) {
			// Shrink back down
			gsap.to(this.currentActiveDot, {
				pixi: { scaleX: 0.5, scaleY: 0.5 },
				duration: sec,
			});
			// 0: dark dot, 1: red dot, 2: dark text, 3: light text
			gsap.to(this.currentActiveDot.children[0], sec, {
				alpha: 0,
			});
			gsap.to(this.currentActiveDot.children[1], sec, {
				alpha: 0,
			});
			gsap.to(this.currentActiveDot.children[2], sec, {
				alpha: 1,
			});
			gsap.to(this.currentActiveDot.children[3], sec, {
				alpha: 0,
			});

			this.currentActiveDot.active = false;
		}

		const activeDot = this.navigationDots.find((navDot) => {
			return navDot.title === scneneName;
		});
		if (activeDot) {
			gsap.to(activeDot, {
				pixi: { scaleX: 0.9, scaleY: 0.9 },
				duration: sec,
				ease: 'back',
			});
			//Fade out dark dot
			gsap.to(activeDot.children[0], sec, {
				alpha: 0,
			});
			// Fade up active dot
			gsap.to(activeDot.children[1], sec, {
				alpha: 1,
			});
			// fade out dark text
			gsap.to(activeDot.children[2], sec, {
				alpha: 0,
			});

			gsap.to(activeDot.children[3], sec, {
				alpha: 1,
			});

			this.currentActiveDot = activeDot;
			activeDot.active = true;
		}
	};

	toggleNavigation = (sprite: PIXI.Sprite) => {
		const duration = 0.7;

		if (this.isNavigationVisible) {
			/**
			 * Close it and it invisible
			 */

			//compress the connector line to left
			gsap.to(this.connectorLine, {
				pixi: { scaleX: 0 },
				duration: duration,
			});
			//Rotate the wheel counterclockwise
			gsap.to(sprite, {
				pixi: { rotation: -30 },
			});
			//Scale down the dots
			gsap.to(this.navigationDots, {
				alpha: 0,
				delay: 0.1,
				pixi: { scaleX: 0, scaleY: 0 },
				stagger: {
					amount: duration,
					from: 'end',
				},
			});
			this.isNavigationVisible = false;
		} else {
			/**
			 * Open it and make it visible
			 */

			gsap.to(this.connectorLine, {
				alpha: 1,
				pixi: { scaleX: 1 },
				duration: duration,
				delay: duration,
			});
			gsap.to(sprite, {
				pixi: { rotation: 30 },
			});

			gsap.to(this.navigationDots, {
				pixi: { scaleX: 0.5, scaleY: 0.5 },
				alpha: 1,
				duration: duration,
				ease: 'back',
				onComplete: () => this.setActive(this.currentActiveDot.title),
				stagger: {
					amount: duration,
					from: 'start',
				},
			});
			this.isNavigationVisible = true;
		}
	};
}
