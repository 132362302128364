import { AbstractScene, SceneSetupFacade } from '../../scenes';
import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { PixiPlugin } from 'gsap/PixiPlugin';

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

export default class Scene5 extends AbstractScene {
	private scene: AbstractScene;
	public name: string = 'Scene 5';
	public index = 4;
	constructor() {
		super();
	}
	setup(index: number): AbstractScene {
		const scene = SceneSetupFacade.setup(index, this);

		// Get reference to the map
		const map = scene.appManager.map.sprite;

		// Set Map to wide coordinates
		gsap.set(map, {
			pixi: { scaleX: 1, scaleY: 1, positionX: 0, positionY: 0 },
		});

		const borderMaskTexture =
			scene.appManager.loader.resources['borderMask'].texture;
		const borderMaskSprite = new PIXI.Sprite(borderMaskTexture);
		borderMaskSprite.x = 0;
		borderMaskSprite.y = 0;
		borderMaskSprite.anchor.set(0);

		const fleetTexture = scene.appManager.loader.resources['fleet'].texture;
		const fleetSprite = new PIXI.Sprite(fleetTexture);

		fleetSprite.alpha = 0;
		fleetSprite.anchor.set(0, 1); // lower left corner
		fleetSprite.x = 960;
		fleetSprite.y = 1080;
		fleetSprite.mask = borderMaskSprite;

		const shipsTexture = scene.appManager.loader.resources['ships'].texture;
		const shipsSprite = new PIXI.Sprite(shipsTexture);
		shipsSprite.anchor.set(0);
		shipsSprite.x = 0;
		shipsSprite.y = 0;
		shipsSprite.alpha = 0;
		shipsSprite.mask = borderMaskSprite;

		// Hack to apply mask and blending mode at same time
		const multiplyFilter = new PIXI.filters.AlphaFilter();
		multiplyFilter.blendMode = PIXI.BLEND_MODES.MULTIPLY;
		shipsSprite.filters = [multiplyFilter];

		scene.stage.addChild(fleetSprite, shipsSprite, borderMaskSprite);

		scene.timeline
			.to(
				fleetSprite,
				{
					alpha: 1,
					duration: 5,
				},
				'<'
			)
			.to(
				fleetSprite,
				{
					alpha: 0,
					duration: 1,
				},
				'>4'
			)
			.to(
				map,
				{
					alpha: 0,
					duration: 3,
				},
				'>'
			)
			.to(
				shipsSprite,
				{
					alpha: 0.8,
					duration: 3,
				},
				'<'
			)
			.to(
				shipsSprite,
				{
					alpha: 0,
					duration: 2,
				},
				'>17'
			);

		this.scene = scene;
		return scene;
	}
}
