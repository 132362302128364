import { AppManager } from '../managers';
import * as SCENES from '../constants/scenes';
import * as PIXI from 'pixi.js';
import SOUND from 'pixi-sound';
PIXI['s' + 'o' + 'u' + 'n' + 'd'] = SOUND;
import gsap from 'gsap';

interface SceneObject {
	narrationSprite: PIXI.Sprite;
	timeline: GSAPTimeline;
}

export default class NarrationText {
	private narrationText: Array<string>;
	private narrationAudio: string;
	public audioDuration: number = -1;
	private textStyle: PIXI.TextStyle;
	private appManager: AppManager;
	public timeline: GSAPTimeline;
	public duration: number = 3;

	constructor(
		appManager: AppManager,
		sceneIndex: number,
		textStyle: PIXI.TextStyle
	) {
		this.appManager = appManager;
		this.narrationText = this.getNarrationText(sceneIndex);
		this.narrationAudio = this.getNarrationAudio(sceneIndex);
		this.textStyle = textStyle;
	}

	// Get the corresponding narration text array from SCENES
	private getNarrationText = (sceneIndex: number) => {
		return SCENES.scenes[sceneIndex].description;
	};

	private getNarrationAudio = (sceneIndex: number) => {
		return SCENES.scenes[sceneIndex].audio;
	};

	public createNarrationText = (): SceneObject => {
		// Create a PIXI Containter for the Text
		const textContainer = new PIXI.Container();
		let accumulator = 0;
		const lineSpacing = 80;

		for (let sentence of this.narrationText) {
			let text = new PIXI.Text(sentence, this.textStyle);
			text.resolution = window.devicePixelRatio;
			text.anchor.set(0, 0);
			text.y = accumulator;
			accumulator += text.height + lineSpacing;
			textContainer.addChild(text);
		}

		const marginLeft = Math.round(
			(AppManager.logicalWidth / 2 - textContainer.width) / 2
		);

		const marginTop = Math.round(
			(AppManager.logicalHeight - textContainer.height) / 2 - 80
		);

		/*
		Debug Output
		console.log('App Width: ', AppManager.logicalWidth);
		console.log('App Height: ', AppManager.logicalHeight);
		console.log(
			'Text Container: width: ' +
				textContainer.width +
				', height: ' +
				textContainer.height
		);
		console.log('Margin Top: ', marginTop);
		console.log('Margin Left', marginLeft);
		 */

		// Turn the PIXI.Text into a Sprite so that we can apply Effects
		const texture = new PIXI.RenderTexture(
			new PIXI.BaseRenderTexture({
				width: textContainer.width,
				height: textContainer.height,
			})
		);
		const blurFilter = new PIXI.filters.BlurFilter();
		const colorMatrix = new PIXI.filters.ColorMatrixFilter();

		this.appManager.app.renderer.render(textContainer, texture);
		textContainer.destroy();

		const narrationSprite = new PIXI.Sprite(texture);
		narrationSprite.x = marginLeft;
		narrationSprite.y = marginTop;
		narrationSprite.alpha = 0;

		// Animate the Blur & ColorMatrix
		const identMatrix =
			// prettier-ignore
			[
			1, 0, 0, 0, 0,
			0, 1, 0, 0, 0,
			0, 0, 1, 0, 0,
			0, 0, 0, 1, 0,
		];

		const contrastMatrix =
			// prettier-ignore
			[
			1, 0, 0, 0,   0,
			0, 1, 0, 0,   0,
			0, 0, 1, 0,   0,
			0, 0, 0, 18, -7,
		];

		// const playAudio = () => {
		// 	// The sound has been pre-loaded to library
		// 	//PIXI.sound.play(this.narrationAudio);
		// 	// Only play if scene is active
		// 	if (
		// 		this.appManager.sceneManager.getCurrentSceneAudio() ===
		// 		this.narrationAudio
		// 	) {
		// 		this.audioDuration = PIXI.sound.duration(this.narrationAudio);
		// 		console.log('Playing: ', this.narrationAudio);
		// 		PIXI.sound.play(this.narrationAudio);
		// 		//console.log('Duration', PIXI.sound.duration(this.narrationAudio));
		// 	}
		// };

		try {
			this.audioDuration = PIXI.sound.duration(this.narrationAudio);
		} catch {
			this.audioDuration = 10;
		}

		//gsap.defaults({ ease: 'none', duration: this.duration });
		const tl = gsap
			.timeline()
			.to(blurFilter, {
				blur: 0,
				ease: 'none',
				duration: this.duration,
			})
			.to(
				contrastMatrix,
				{
					endArray: identMatrix,
					ease: 'power2.in',
					duration: this.duration,
				},
				'<'
			)
			.to(
				narrationSprite,
				{
					alpha: 1,
					duration: 0.3,
					ease: 'power2.in',
				},
				'<'
			);
		// try adding duration from audio itself

		blurFilter.blur = 10; // blur amount also governs the amount of blend
		colorMatrix.matrix = contrastMatrix;
		narrationSprite.filters = [blurFilter, colorMatrix];
		narrationSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY;
		this.timeline = tl;

		return { timeline: tl, narrationSprite: narrationSprite };
	};
}
