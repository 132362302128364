import { AbstractScene, SceneSetupFacade } from '..';

export default class Scene26 extends AbstractScene {
	private scene: AbstractScene;
	public name: string = 'Scene 26';
	public index = 25;
	constructor() {
		super();
	}

	setup(index: number): AbstractScene {
		const scene = SceneSetupFacade.setup(index, this);
		this.scene = scene;
		return scene;
	}
}
