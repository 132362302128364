import { AppManager } from '../managers';
import * as PIXI from 'pixi.js';

export default class Border {
	appManager: AppManager;

	constructor(appManger: AppManager) {
		this.appManager = appManger;
	}

	create = () => {
		// The Map Border

		let Sprite = PIXI.Sprite;

		let borderCnt = new PIXI.Container();
		let id = this.appManager.loader.resources.mapElements.textures;

		// Parse the images form the loaded Atlas

		// First Row
		let lc1 = new Sprite(id['01_lc.png']);
		let l1 = new Sprite(id['01_l.png']);
		let m1 = new Sprite(id['01_m.png']);
		let r1 = new Sprite(id['01_l.png']);
		let rc1 = new Sprite(id['01_rc.png']);

		// Second Row
		let l2 = new Sprite(id['02_l.png']);
		let r2 = new Sprite(id['02_r.png']);

		// Third Row
		let l3 = new Sprite(id['03_l.png']);
		let r3 = new Sprite(id['03_r.png']);

		// Fourth Row
		let lc4 = new Sprite(id['04_lc.png']);
		let l4 = new Sprite(id['04_l.png']);
		let m4 = new Sprite(id['04_m.png']);
		let r4 = new Sprite(id['04_r.png']);
		let rc4 = new Sprite(id['04_rc.png']);

		// Layout First Row
		l1.x = lc1.width;
		m1.x = l1.x + l1.width;
		r1.x = m1.x + m1.width;
		rc1.x = r1.x + r1.width;

		// Layout Second Row
		l2.y = l1.height;
		r2.x = AppManager.logicalWidth - r2.width;
		r2.y = rc1.height;

		// Layout Third Row
		l3.y = l2.y + l2.height;
		r3.y = r2.y + r2.height;
		r3.x = AppManager.logicalWidth - r3.width;

		// Layout Fourth Row
		lc4.y = l3.y + l3.height;
		l4.x = lc4.x + lc4.width;
		l4.y = lc4.y;
		m4.x = l4.x + l4.width;
		m4.y = l4.y;
		r4.x = m4.x + m4.width;
		r4.y = m4.y;
		rc4.x = AppManager.logicalWidth - rc4.width;
		rc4.y = r4.y;

		borderCnt.addChild(lc1, l1, m1, r1, rc1);
		borderCnt.addChild(l2, r2, l3, r3);
		borderCnt.addChild(lc4, l4, m4, r4, rc4);

		borderCnt.children.forEach((child) => {
			(child as PIXI.Graphics).blendMode = PIXI.BLEND_MODES.MULTIPLY;
		});

		return borderCnt;
	};
}
