import * as PIXI from 'pixi.js';

export default class Map {
	public sprite: PIXI.Sprite;

	create = () => {
		const Sprite = PIXI.Sprite;
		const mapCnt = new PIXI.Container();
		const id = PIXI.Loader.shared.resources['map'].texture;
		const mapSprite = new Sprite(id);
		this.sprite = mapSprite;
		mapCnt.addChild(mapSprite);

		return mapCnt;
	};
}
