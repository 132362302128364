import { AppManager } from './app/managers';

import { RESOLUTIONS } from './app/constants';
import * as PIXI from 'pixi.js';
import './css/styles.css';

export interface ConfigOptions {
	antialias: boolean;
	width: number;
	height: number;
	resolution: number;
	autoDensity: boolean;
	powerPreference: string;
	sharedLoader: boolean;
}
const appConfigOptions: ConfigOptions = {
	antialias: true,
	width: RESOLUTIONS.FULLHD.width,
	height: RESOLUTIONS.FULLHD.height,
	resolution: window.devicePixelRatio,
	autoDensity: true,
	sharedLoader: true,
	powerPreference: 'low-power', //default, low-power, high-performance
};

const app = new PIXI.Application(appConfigOptions);
AppManager.getInstance(app, appConfigOptions);
