import { AbstractScene, SceneSetupFacade } from '../../scenes';
import * as PIXI from 'pixi.js';

import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

export default class Scene1 extends AbstractScene {
	private scene: AbstractScene;
	public name: string = 'Scene 1';
	public index = 0;

	constructor() {
		super();
	}

	setup(index: number): AbstractScene {
		// Load the narration track
		const scene = SceneSetupFacade.setup(index, this);

		// Get reference to main map
		const map = scene.appManager.map.sprite;

		scene.timeline.set(
			map,
			{
				pixi: { scaleX: 1, scaleY: 1, positionX: 0, positionY: 0 },
				duration: 0,
			},
			0
		);

		this.scene = scene;
		return scene;
	}
}
