import { NarrationText } from '../scenes';
import gsap from 'gsap';
import { AppManager } from '../managers';
import * as PIXI from 'pixi.js';

export enum SceneState {
	PLAYING,
	PAUSED,
	START,
	END,
}

export default abstract class AbstractScene {
	public appManager: AppManager;
	protected sceneState: SceneState;
	protected sceneContainer: PIXI.Container;
	public stage: PIXI.Container;
	public name: string;
	public index: number;
	public narrationSprite: PIXI.Sprite;
	public narrationAudio: string;
	public timeline: GSAPTimeline;
	protected textNarration: NarrationText;

	/**
	 * Basic initialization of a scene, passing in the PIXI.APP
	 * @param app
	 * @param sceneSwitcher
	 */
	init(appManager: AppManager): AbstractScene {
		this.appManager = appManager;
		this.timeline = gsap.timeline();
		this.stage = new PIXI.Container();
		return this;
	}
	getName() {
		return this.name;
		//return 'Scene ' + this.constructor.name.substr(5);
	}

	getIndex() {
		//return parseInt(this.constructor.name.substr(5)) - 1;
		return this.index;
	}

	abstract setup(index: number): AbstractScene;
}
