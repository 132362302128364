// Duration is in seconds
// Get real timings from voice-over narration
// include field for mp3 audio track
export const scenes = [
	{
		id: '1A',
		title: 'Scene 1',
		description: [
			'April 1776',
			'Washington and his staff reach New York.',
			'The city officially becomes the headquarters of the Continental Army.',
			'The army consists of 10,000 troops, on paper at least...',
		],
		audio: 'narration1A',
		duration: 15,
	},
	{
		id: '2A',
		title: 'Scene 2',
		description: [
			'The colonies begin constructing defenses in anticipation of an attack.',
		],
		audio: 'narration2A',
		duration: 5,
	},
	{
		id: '3A',
		title: 'Scene 3',
		description: [
			"They don't know where or when the British will attack, but they know they are coming...",
		],
		audio: 'narration3A',
		duration: 5,
	},
	{
		id: '4A',
		title: 'Scene 4',
		description: [
			'The British fleet begins to sail through the Narrows, dropping anchor off of Staten Island.',
		],
		audio: 'narration4A',
		duration: 6,
	},
	{
		id: '5A',
		title: 'Scene 5',
		description: [
			'"This morning as I was upstairs in an outhouse I spied, as I peeped out the Bay, something resembling a wood of pine trees trimmed. I declare at my noticing this that I could not believe my eyes, but keeping my eyes fixed at the very spot, judge you of my surprise, when in about ten minutes, the whole Bay was as full of shipping as ever it could be. I do declare that I thought all London was in afloat."',
			'-Private Daniel McCurtin, American rifleman, stationed in New York, May 29 1776',
		],
		audio: 'narration5A',
		duration: 28,
	},
	{
		id: '6A',
		title: 'Scene 6',
		description: [
			'July 9th',
			'Washington reads the Declaration of Independence to the troops.',
		],
		audio: 'narration6A',
		duration: 5,
	},
	{
		id: '7A',
		title: 'Scene 7',
		description: [
			'By mid-July, Congress draws a plan to send Washington 14,000 men to defend New York City.',
		],
		audio: 'narration7A',
		duration: 7,
	},
	{
		id: '8A',
		title: 'Scene 8',
		description: [
			'Most are untrained and undisciplined.',
			'Poor sanitation results in an outbreak of typhus.',
			'One quarter of the American troops fall ill.',
		],
		audio: 'narration8A',
		duration: 11,
	},
	{
		id: '9A',
		title: 'Scene 9',
		description: [
			'By mid-August, there are more than 400 ships carrying 24,000 soldiers and 10,000 sailors.',
			'This is the largest fleet ever assembled.',
		],
		audio: 'narration9A',
		duration: 11,
	},
	{
		id: '10A',
		title: 'Scene 10',
		description: ['The British completely control the New York harbor.'],
		duration: 3,
		audio: 'narration10A',
	},
	{
		id: '11A',
		title: 'Scene 11',
		description: [
			'The British move 20,000 troops from Staten Island to Brooklyn.',
			'A small detachment of Americans fire a few shots and escape north.',
		],
		audio: 'narration11A',
		duration: 6,
	},
	{
		id: '12A',
		title: 'Scene 12',
		description: [
			'Washington is still unsure where the British will attack.',
			'He moves more men into Brooklyn and occupies the Heights.',
			'Others remain in Manhattan.',
		],
		audio: 'narration12A',
		duration: 6,
	},
	{
		id: '13A',
		title: 'Scene 13',
		description: [
			'The British begin to move.',
			'General James Grant leads 2,000 Royal Marines and two companies of Loyalists to the Martense Pass.',
		],
		audio: 'narration13A',
		duration: 4,
	},
	{
		id: '14A',
		title: 'Scene 14',
		description: [
			'Generals Clinton and Cornwallis lead 4,000 men across Kings Highway.',
		],
		audio: 'narration14A',
		duration: 4,
	},
	{
		id: '15A',
		title: 'Scene 15',
		description: [
			'Clinton is joined by General Howe, who leaves Flatlands at midnight, at the head of the main flanking army, an addtional 6,000 men in a column that stretches for two miles, moving slowly forward by moonlight.',
		],
		audio: 'narration15A',
		duration: 9,
	},
	{
		id: '16A',
		title: 'Scene 16',
		description: [
			'General Sullivan sends 800 men to Flatbush Pass and 800 to Bedford Pass.',
		],
		audio: 'narration16A',
		duration: 4,
	},
	{
		id: '17A',
		title: 'Scene 17',
		description: [
			'General Lord Stirling moves into the southern Gowanus with 800 men.',
			'And additional 500 guard the area near the Gowanus.',
		],
		audio: 'narration17A',
		duration: 5,
	},
	{
		id: '18A',
		title: 'Scene 18',
		description: [
			'Admiral Howe tries to move ships up into the East River.',
			'Westerly winds work against him.',
		],
		audio: 'narration18A',
		duration: 4,
	},
	{
		id: '19A',
		title: 'Scene 19',
		description: [
			"Sullivan moves 500 of his men to support Stirling's flank.",
		],
		audio: 'narration19A',
		duration: 3,
	},
	{
		id: '20A',
		title: 'Scene 20',
		description: [
			'Cornwallis and the Hessians arrive at Flatbush and separate.',
			'The Hessians make camp.',
		],
		audio: 'narration20A',
		duration: 5,
	},
	{
		id: '21A',
		title: 'Scene 21',
		description: [
			"General Grant's troops discover a ripe watermelon patch.",
			'Across the field, an American company is also eating them.',
		],
		audio: 'narration21A',
		duration: 5,
	},
	{
		id: '22A',
		title: 'Scene 22',
		description: [
			'The first shots are exchanged, and the battle officially begins',
		],
		audio: 'narration22A',
		duration: 3,
	},
	{
		id: '23A',
		title: 'Scene 23',
		description: [
			"That same night, Cornwallis and General Howe arrive at Howard's Inn, as they move to outflank the Americans.",
		],
		audio: 'narration23A',
		duration: 5,
	},
	{
		id: '24A',
		title: 'Scene 24',
		description: [
			'Hearing the gunfire at the watermelong patch, Stirling rouses his men, two companies each from Maryland and Delaware.',
		],
		audio: 'narration24A',
		duration: 5,
	},
	{
		id: '25A',
		title: 'Scene 25',
		description: [
			'Washington moves from his headquarters to the top of Pokiesburg hill, overlooking the Gowanus.',
		],
		audio: 'narration25A',
		duration: 4,
	},
	{
		id: '26A',
		title: 'Scene 26',
		description: [
			'Stirling and Sullivan are perplexed: despite their positions and readiness, neither Grant nor the Hessians, attack',
		],
		audio: 'narration26A',
		duration: 5,
	},
	{
		id: '27A',
		title: 'Scene 27',
		description: [
			'Cornwallis continues to move slowly through the dense forest.',
			'There are no Americans defending that approach.',
		],
		audio: 'narration27A',
		duration: 4,
	},
	{
		id: '28A',
		title: 'Scene 28',
		description: [
			"At 9AM Cornwallis' men fire a cannon in Bedford Heights, signaling the other brigades to attack",
		],
		audio: 'narration28A',
		duration: 5,
	},
	{
		id: '29A',
		title: 'Scene 29',
		description: [
			'Stirling holds his position as the British attempt a cavalry charge along the shoreline, but are driven back.',
		],
		audio: 'narration29A',
		duration: 4,
	},
	{
		id: '30A',
		title: 'Scene 30',
		description: [
			"Sullivan's men are forced back and there is hand-to-hand fighting in the forest.",
		],
		audio: 'narration30A',
		duration: 3,
	},
	{
		id: '31A',
		title: 'Scene 31',
		description: [
			"The Hessians take no quarter, massacring as many of Sullivan's men as they can.",
		],
		audio: 'narration31A',
		duration: 3,
	},
	{
		id: '32A',
		title: 'Scene 32',
		description: [
			'Stirling releases the Delaware companies to escape, and leads the 400 Marylanders down the Gowanus Road toward the Old Stone House.',
		],
		audio: 'narration32A',
		duration: 6,
	},
	{
		id: '33A',
		title: 'Scene 33',
		description: [
			'Arriving at the Old Stone House, Stirling finds 2000 British Grenadiers.',
		],
		audio: 'narration33A',
		duration: 3,
	},
	{
		id: '34A',
		title: 'Scene 34',
		description: [
			'Stirling and his men charge the British at the house five times and, twice, force the British to retreat',
		],
		audio: 'narration34A',
		duration: 4,
	},
	{
		id: '35A',
		title: 'Scene 35',
		description: [
			'Surrounded, Stirling releases his men, to try to escape.',
			"He surrrenders to the Hessian general, so the British can't claim the victory.",
		],
		audio: 'narration35A',
		duration: 5,
	},
	{
		id: '36A',
		title: 'Scene 36',
		description: [
			'The remnants of the American units attempt to swim across the estuary.',
			'Some drown.',
			'The Americans fire cannon from the hill in what is now Carrol Gardens.',
		],
		audio: 'narration36A',
		duration: 7,
	},
	{
		id: '37A',
		title: 'Scene 37',
		description: [
			"Cornwallis' troops, having marched all night, take the field.",
			'The Heights before them are covered with felled trees to prevent easy attack and are steeper than Bunker Hill in Boston, where General Howe and the British had lost 40% of their soldiers.',
		],
		audio: 'narration37A',
		duration: 11,
	},
	{
		id: '38A',
		title: 'Scene 38',
		description: [
			'General Howe decides not to continue the battle, and instead orders siege maneuvers to begin',
		],
		audio: 'narration38A',
		duration: 4,
	},
	{
		id: '39A',
		title: 'Scene 39',
		description: [
			'Night falls as the Americans organize and watch the best army in the world begin to dig in for a siege.',
		],
		audio: 'narration39A',
		duration: 4,
	},
	{
		id: '40A',
		title: 'Scene 40',
		description: [
			'It begins to rain and continues all the next day.',
			'The Americans have little protection and no food other than water and hardtack.',
		],
		audio: 'narration40A',
		duration: 5,
	},
	{
		id: '41A',
		title: 'Scene 41',
		description: [
			"Washington, impressed with Stirling's success, wants to continue the fight.",
		],
		audio: 'narration41A',
		duration: 3,
	},
	{
		id: '42A',
		title: 'Scene 42',
		description: [
			'The Americans watch as the British trenches expand, advancing slowly toward their lines.',
			'Washington orders a retreat.',
		],
		audio: 'narration42A',
		duration: 5,
	},
	{
		id: '43A',
		title: 'Scene 43',
		description: [
			'During the afternoon, rescue boats begin to arrive from all over the harbor at the landing in the tiny town of Brooklyn.',
			'The wind is blowing from the north, making it impossible for the Bristish ships to come up the river.',
		],
		audio: 'narration34A',
		duration: 7,
	},
	{
		id: '44A',
		title: 'Scene 44',
		description: [
			'All night, the Americans keep their fires burning.',
			"The horses' hooves are muffled.",
			'The men are warned to keep completely silent as they move.',
		],
		audio: 'narration44A',
		duration: 7,
	},
	{
		id: '45A',
		title: 'Scene 45',
		description: [
			'At the landing, the first troops begin to embark.',
			'A fog enshrouds the river.',
			'They begin to cross, oars muffled',
			'Boats are loaded to the gunnels',
		],
		audio: 'narration45A',
		duration: 6,
	},
	{
		id: '46A',
		title: 'Scene 46',
		description: [
			'All night, they slowly withdraw, leaving only the guards to tend the fires and call the hours.',
		],
		audio: 'narration46A',
		duration: 3,
	},
	{
		id: '47A',
		title: 'Scene 47',
		description: [
			'The entire army, their equipment, and horses are all brought across, leaving only four small cannon that had been rendered useless.',
		],
		audio: 'narration47A',
		duration: 5,
	},
	{
		id: '48A',
		title: 'Scene 48',
		description: [
			'Legend has it that George Washington was in the last boat, with his horse.',
			'Howe and his cohort arrived at the landing just before eight in the morning.',
			'Some say he could see Washington on the river.',
		],
		audio: 'narration48A',
		duration: 8,
	},
	{
		id: '49A',
		title: 'Scene 49',
		description: [
			'The Americans lost the Battle of Brooklyn, but they survived to fight again.',
		],
		audio: 'narration49A',
		duration: 3,
	},
	{
		id: '50A',
		title: 'Scene 50',
		description: [
			'Retreat is a legitimate strategy of war.',
			"The successful retreat was noted with concern in the British newspapers when Howe's report of the battle was delivered.",
		],
		audio: 'narration50A',
		duration: 7,
	},
];
