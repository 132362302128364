import { AppManager } from '../managers';
import { ASSETS, TYPE, Asset } from '../constants/assets';
import { EVENTS } from '../constants';
import { Subject, Observer } from '../misc';
import * as PIXI from 'pixi.js';
import SOUND from 'pixi-sound';
PIXI['s' + 'o' + 'u' + 'n' + 'd'] = SOUND;

export default class AssetManager implements Subject {
	appManager: AppManager;
	private _observers: Array<Observer> = [];

	constructor(appManager: AppManager) {
		this.appManager = appManager;
		this.loadResources(appManager.resolution);
	}
	registerObserver(o: Observer): void {
		this._observers.push(o);
	}
	removeObserver(o: Observer): void {
		let index = this._observers.indexOf(o);
		this._observers.splice(index, 1);
	}
	notifyObservers(): void {
		for (let observer of this._observers) {
			observer.notify({ type: EVENTS.ASSETS_LOADED });
		}
	}

	loadResources = (resolution: number) => {
		// We filter assets based on resolution
		let assets: Array<Asset> = ASSETS.filter((asset) => {
			if (asset.density) {
				if (asset.density === resolution) return asset;
			} else {
				if (asset.type === 'SOUND') {
					// add to sound library
					PIXI.sound.add(asset.name, asset.url);
					return asset;
				}
				return asset;
			}
		});

		assets.map((asset) => {
			// add the asset to the loader
			this.appManager.loader.add(asset.name, asset.url);
		});

		// When all the assets have been loaded, call assetsLoaded
		this.appManager.loader.load(this.onAssetsLoaded);
	};

	onAssetsLoaded = () => {
		this.notifyObservers();
		this.appManager.resize();
	};
}
