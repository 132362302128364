/**
 * Images
 */

export enum TYPE {
	IMAGE = 'IMAGE',
	JSON = 'JSON',
	SOUND = 'SOUND',
}

export enum DENSITY {
	SINGLE = 1,
	DOUBLE = 2,
}

export interface Asset {
	name: string;
	url: string;
	type: TYPE;
	density?: DENSITY;
}

const assets: Array<Asset> = [
	{
		name: 'map',
		url: 'images/map@1x.jpg',
		type: TYPE.IMAGE,
		density: DENSITY.SINGLE,
	},
	{
		name: 'map',
		url: 'images/map@2x.jpg',
		type: TYPE.IMAGE,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'mapBackground',
		url: 'images/mapBackground@1x.jpg',
		type: TYPE.IMAGE,
		density: DENSITY.SINGLE,
	},
	{
		name: 'mapBackground',
		url: 'images/mapBackground@2x.jpg',
		type: TYPE.IMAGE,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'mapElements',
		url: 'images/map_elements@1x.json',
		type: TYPE.JSON,
		density: DENSITY.SINGLE,
	},
	{
		name: 'mapElements',
		url: 'images/map_elements@2x.json',
		type: TYPE.JSON,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'tower',
		url: 'images/fortification_icon@1x.png',
		type: TYPE.IMAGE,
		density: DENSITY.SINGLE,
	},
	{
		name: 'tower',
		url: 'images/fortification_icon@2x.png',
		type: TYPE.IMAGE,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'warship',
		url: 'images/warship@1x.png',
		type: TYPE.IMAGE,
		density: DENSITY.SINGLE,
	},
	{
		name: 'warship',
		url: 'images/warship@2x.png',
		type: TYPE.IMAGE,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'cannon1',
		url: 'images/cannon_1_icon@1x.png',
		type: TYPE.IMAGE,
		density: DENSITY.SINGLE,
	},
	{
		name: 'cannon1',
		url: 'images/cannon_1_icon@2x.png',
		type: TYPE.IMAGE,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'cannon2',
		url: 'images/cannon_2_icon@1x.png',
		type: TYPE.IMAGE,
		density: DENSITY.SINGLE,
	},
	{
		name: 'cannon2',
		url: 'images/cannon_2_icon@2x.png',
		type: TYPE.IMAGE,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'cannon3',
		url: 'images/cannon_3_icon@1x.png',
		type: TYPE.IMAGE,
		density: DENSITY.SINGLE,
	},
	{
		name: 'cannon3',
		url: 'images/cannon_3_icon@2x.png',
		type: TYPE.IMAGE,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'fleet',
		url: 'images/fleet@1x.png',
		type: TYPE.IMAGE,
		density: DENSITY.SINGLE,
	},
	{
		name: 'fleet',
		url: 'images/fleet@2x.png',
		type: TYPE.IMAGE,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'ships',
		url: 'images/ships@1x.jpg',
		type: TYPE.IMAGE,
		density: DENSITY.SINGLE,
	},
	{
		name: 'ships',
		url: 'images/ships@2x.jpg',
		type: TYPE.IMAGE,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'fireworks',
		url: 'images/fireworks@1x.json',
		type: TYPE.JSON,
		density: DENSITY.SINGLE,
	},
	{
		name: 'fireworks',
		url: 'images/fireworks@2x.json',
		type: TYPE.JSON,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'rockets',
		url: 'images/rockets@1x.json',
		type: TYPE.JSON,
		density: DENSITY.SINGLE,
	},
	{
		name: 'rockets',
		url: 'images/rockets@2x.json',
		type: TYPE.JSON,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'george_reading',
		url: 'images/george_reading@1x.jpg',
		type: TYPE.IMAGE,
		density: DENSITY.SINGLE,
	},
	{
		name: 'george_reading',
		url: 'images/george_reading@2x.jpg',
		type: TYPE.IMAGE,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'declaration',
		url: 'images/declaration@1x.jpg',
		type: TYPE.IMAGE,
		density: DENSITY.SINGLE,
	},
	{
		name: 'declaration',
		url: 'images/declaration@2x.jpg',
		type: TYPE.IMAGE,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'vintageBG',
		url: 'images/vintage_background@1x.jpg',
		type: TYPE.IMAGE,
		density: DENSITY.SINGLE,
	},
	{
		name: 'vintageBG',
		url: 'images/vintage_background@2x.jpg',
		type: TYPE.IMAGE,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'mapMask',
		url: 'images/map_mask@1x.png',
		type: TYPE.IMAGE,
		density: DENSITY.SINGLE,
	},
	{
		name: 'mapMask',
		url: 'images/map_mask@2x.png',
		type: TYPE.IMAGE,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'borderMask',
		url: 'images/border_mask@1x.png',
		type: TYPE.IMAGE,
		density: DENSITY.SINGLE,
	},
	{
		name: 'borderMask',
		url: 'images/border_mask@2x.png',
		type: TYPE.IMAGE,
		density: DENSITY.DOUBLE,
	},
	{
		name: 'narration1A',
		url: 'sounds/1A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration2A',
		url: 'sounds/2A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration3A',
		url: 'sounds/3A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration4A',
		url: 'sounds/4A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration5A',
		url: 'sounds/5A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration6A',
		url: 'sounds/6A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration7A',
		url: 'sounds/7A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration8A',
		url: 'sounds/8A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration9A',
		url: 'sounds/9A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration10A',
		url: 'sounds/10A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration11A',
		url: 'sounds/11A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration12A',
		url: 'sounds/12A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration13A',
		url: 'sounds/13A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration14A',
		url: 'sounds/14A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration15A',
		url: 'sounds/15A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration16A',
		url: 'sounds/16A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration17A',
		url: 'sounds/17A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration18A',
		url: 'sounds/18A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration19A',
		url: 'sounds/19A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration20A',
		url: 'sounds/20A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration21A',
		url: 'sounds/21A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration22A',
		url: 'sounds/22A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration23A',
		url: 'sounds/23A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration24A',
		url: 'sounds/24A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration25A',
		url: 'sounds/25A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration26A',
		url: 'sounds/26A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration27A',
		url: 'sounds/27A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration28A',
		url: 'sounds/28A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration29A',
		url: 'sounds/29A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration30A',
		url: 'sounds/30A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration31A',
		url: 'sounds/31A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration32A',
		url: 'sounds/32A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration33A',
		url: 'sounds/33A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration34A',
		url: 'sounds/34A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration35A',
		url: 'sounds/35A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration36A',
		url: 'sounds/36A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration37A',
		url: 'sounds/37A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration38A',
		url: 'sounds/38A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration39A',
		url: 'sounds/39A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration40A',
		url: 'sounds/40A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration41A',
		url: 'sounds/41A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration42A',
		url: 'sounds/42A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration43A',
		url: 'sounds/43A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration44A',
		url: 'sounds/44A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration45A',
		url: 'sounds/45A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration46A',
		url: 'sounds/46A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration47A',
		url: 'sounds/47A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration48A',
		url: 'sounds/48A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration49A',
		url: 'sounds/49A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'narration50A',
		url: 'sounds/50A.mp3',
		type: TYPE.SOUND,
	},
	{
		name: 'pop',
		url: 'sounds/pop.mp3',
		type: TYPE.SOUND,
	},
];

export { assets as ASSETS };
