import { AbstractScene, SceneSetupFacade } from '../../scenes';
import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { PixiPlugin } from 'gsap/PixiPlugin';
import * as filters from 'pixi-filters';

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

export default class Scene6 extends AbstractScene {
	private scene: AbstractScene;
	public name: string = 'Scene 6';
	public index = 5;
	constructor() {
		super();
	}

	setup(index: number): AbstractScene {
		const scene = SceneSetupFacade.setup(index, this);

		// Set map
		const map = scene.appManager.map.sprite;

		gsap.set(map, {
			pixi: { scaleX: 1, scaleY: 1, positionX: 0, positionY: 0 },
			alpha: 0,
		});

		// Half Mask
		const maskTexture = this.appManager.loader.resources['borderMask']
			.texture;
		const maskSprite = new PIXI.Sprite(maskTexture);
		maskSprite.x = 0;
		maskSprite.y = 0;
		maskSprite.anchor.set(0);

		// George Washington
		const georgeTexture = this.appManager.loader.resources['george_reading']
			.texture;
		const georgeSprite = new PIXI.Sprite(georgeTexture);
		georgeSprite.x = 960;
		georgeSprite.y = 0;
		georgeSprite.alpha = 0;
		georgeSprite.anchor.set(0);
		georgeSprite.mask = maskSprite;

		// Declaration
		const declarationTexture = this.appManager.loader.resources['declaration']
			.texture;
		const declarationSprite = new PIXI.Sprite(declarationTexture);
		declarationSprite.x = 960;
		declarationSprite.y = 0;
		declarationSprite.alpha = 0;
		declarationSprite.anchor.set(0);
		declarationSprite.mask = maskSprite;

		// Vintage Background
		const harborTexture = this.appManager.loader.resources['vintageBG']
			.texture;
		const harborSprite = new PIXI.Sprite(harborTexture);
		harborSprite.x = 0;
		harborSprite.y = 0;
		harborSprite.alpha = 0;
		harborSprite.anchor.set(0);
		harborSprite.mask = maskSprite;

		function fit(
			value: number,
			oldmin: number,
			oldmax: number,
			newmin: number,
			newmax: number
		): number {
			let num = value - oldmin;
			return num * (newmax / (oldmax - oldmin)) + newmin;
		}

		scene.stage.addChild(
			georgeSprite,
			declarationSprite,
			maskSprite,
			harborSprite
		);

		// Add 3 sec for fade-up duration on text
		scene.timeline
			.to(
				georgeSprite,
				{
					alpha: 1,
					duration: 3,
				},
				'<-3'
			)
			.to(
				georgeSprite,
				{
					duration: 4,
				},
				'>'
			)
			.to(
				georgeSprite,
				{
					alpha: 0,
					duration: 3,
				},
				'>'
			)
			.to(
				scene.narrationSprite,
				{
					alpha: 0,
					duration: 3,
				},
				'<'
			)
			.to(
				declarationSprite,
				{
					alpha: 1,
					x: 0,
					y: 0,
					duration: 5,
				},
				'<'
			)
			.to(declarationSprite, {
				duration: 4,
			})
			.to(declarationSprite, {
				alpha: 0,
				duration: 2,
			})
			.to(
				harborSprite,
				{
					alpha: 1,
					duration: 3,
				},
				'<'
			);
		// .to(
		// 	{ frame: 0 },
		// 	{
		// 		frame: animatedFirework.totalFrames - 1,
		// 		onUpdate: function () {
		// 			const target = this.targets()[0].frame;
		// 			animatedFirework.gotoAndStop(Math.round(target));
		// 		},
		// 		ease: 'none',
		// 		duration: 1,
		// 	}
		// );

		// Fireworks

		// the rocket sprite Sheet
		const rocketsSpriteSheet = this.appManager.loader.resources['rockets']
			.spritesheet;

		// the blast sprite sheet
		const fireworksSpriteSheet = this.appManager.loader.resources['fireworks']
			.spritesheet;

		const rocketsArray = [];
		const fireworksArray = [];

		const fireworksNum = 10;

		function getHueShiftFilter() {
			const hueShift = new filters.AdjustmentFilter({
				red: fit(Math.random(), 0, 1, 1, 1.5),
				green: fit(Math.random(), 0, 1, 1, 1.5),
				blue: fit(Math.random(), 0, 1, 1, 1.5),
				saturation: fit(Math.random(), 0, 1, 1, 1.5),
			});
			hueShift.blendMode = PIXI.BLEND_MODES.SCREEN;

			return hueShift;
		}

		console.log(rocketsSpriteSheet);

		function makeFireworks() {
			for (let i = 0; i < fireworksNum; i++) {
				const blastSprite = new PIXI.AnimatedSprite(
					fireworksSpriteSheet.animations['firework']
				);

				const rocketSprite = new PIXI.AnimatedSprite(
					rocketsSpriteSheet.animations['rocket']
				);

				blastSprite.x = fit(Math.random(), 0, 1, 300, 1500);
				blastSprite.y = fit(Math.random(), 0, 1, 100, 200);

				// give rocket same x, but offset y
				rocketSprite.x = blastSprite.x;
				rocketSprite.y = 900;

				blastSprite.alpha = 0;
				rocketSprite.alpha = 0;

				blastSprite.scale.set(fit(Math.random(), 0, 1, 0.8, 1.5));
				blastSprite.anchor.set(0.50625, 0.218519);
				// rocketSprite.anchor.set(0.488281, 0.103516);

				blastSprite.blendMode = PIXI.BLEND_MODES.SCREEN;
				rocketSprite.blendMode = PIXI.BLEND_MODES.SCREEN;

				// randomize rotation by +/- 10 degrees
				blastSprite.rotation = fit(
					Math.random(),
					0,
					1,
					(-Math.PI / 180) * 10,
					(Math.PI / 180) * 10
				);

				// randomize color
				blastSprite.filters = [getHueShiftFilter()];

				// merge both sprites into one array
				fireworksArray.push({ rocket: rocketSprite, blast: blastSprite });
				scene.stage.addChild(rocketSprite, blastSprite);
			}
		}
		makeFireworks();

		// Add fireworks - since rockets are paired with blasts, we can use a single iterator
		fireworksArray.map((firework, i) => {
			scene.timeline
				// first the rocket
				.to(
					{ frame: 0 },
					{
						frame: firework.rocket.totalFrames - 1,
						onUpdate: function () {
							const target = this.targets()[0].frame;
							if (target > 1) {
								firework.rocket.alpha = 1;
								if (target >= firework.rocket.totalFrames - 1) {
									firework.rocket.alpha = 0;
								}
							}
							firework.rocket.gotoAndStop(Math.round(target));
						},
						ease: 'none',
						duration: 1,
					},
					// delay between rockets
					`<-${fit(Math.random(), 0, 1, 0.2, 1)}`
				)
				.to(
					firework.rocket,
					{
						pixi: {
							positionY: firework.blast.y,
							scaleX: 0.5,
						},
						duration: 0.5,
						ease: 'power1.in',
					},
					'<'
				)
				// then the blast
				.to(
					{ frame: 0 },
					{
						frame: firework.blast.totalFrames - 1,
						onUpdate: function () {
							const target = this.targets()[0].frame;
							if (target > 1) {
								firework.blast.alpha = 1;
								if (target >= firework.blast.totalFrames - 1) {
									firework.blast.alpha = 0;
								}
							}
							firework.blast.gotoAndStop(Math.round(target));
						},
						ease: 'none',
						duration: 1,
					},
					'>'
				)
				.to(
					firework.blast,
					{
						alpha: 1,
						duration: 0.3,
						ease: 'none',
					},
					'<'
				)
				.to(firework.blast, {
					duration: 1,
					alpha: 0,
				});
		});

		this.scene = scene;
		return scene;
	}
}
