import { AbstractScene, SceneSetupFacade } from '..';

export default class Scene44 extends AbstractScene {
	private scene: AbstractScene;
	public name: string = 'Scene 44';
	public index = 43;

	constructor() {
		super();
	}
	setup(index: number): AbstractScene {
		const scene = SceneSetupFacade.setup(index, this);
		this.scene = scene;
		return scene;
	}
}
