import { Observer, Subject } from './../misc/patterns';
import { ConfigOptions } from './../../index';
import { Asset } from './../constants/assets';
import * as PIXI from 'pixi.js';
import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
import { SceneNavigator } from '../navigation';
import { AssetManager, SceneManager } from '../managers';
import { Map } from '../map';
import Scene1 from '../scenes/Scenes/Scene1';
import Border from '../border/Border';

// Custom
// import { CounterClockwiseScene } from '../scenes/counterClockwise/counterClockwise';
// import { ClockwiseScene } from '../scenes/clockwise/clockwise';
// import { Engine } from '../engine';

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

/**
 * Class to initialize a Pixi stage
 */

export default class AppManager implements Observer {
	private static instance: AppManager;
	public static logicalWidth: number;
	public static logicalHeight: number;
	app: PIXI.Application;
	assetManager: AssetManager;
	// engine: Engine;
	resolution: number;
	mapMask: PIXI.Sprite;
	map: Map;
	border: Border;
	sceneNavigator: SceneNavigator;
	sceneManager: SceneManager;
	sceneContainer: PIXI.Container;
	loader: PIXI.Loader;

	private constructor(app: PIXI.Application) {
		this.app = app;
		this.resolution = window.devicePixelRatio || 1;
		this.loader = PIXI.Loader.shared;

		this.assetManager = new AssetManager(this);

		this.assetManager.registerObserver(this);

		// Add the canvas to the <body>
		document.body.appendChild(this.app.view);

		this.init();
	}
	notify(value: any): void {
		if (value) {
			this.onAssetsLoaded();
		}
	}

	public static getInstance(
		app: PIXI.Application,
		config: ConfigOptions
	): AppManager {
		if (!AppManager.instance) {
			AppManager.instance = new AppManager(app);
			AppManager.logicalWidth = config.width;
			AppManager.logicalHeight = config.height;
		}

		return AppManager.instance;
	}

	public onAssetsLoaded = () => {
		console.log(
			'%c All assets loaded!',
			'background: #000; color: #ff6633; padding: 10px'
		);

		// Get the mask
		const maskTexture = this.loader.resources['mapMask'].texture;
		const mapMask = new PIXI.Sprite(maskTexture);

		// Get the map background
		const mapBackgroundTexture = this.loader.resources['mapBackground']
			.texture;
		const mapBackground = new PIXI.Sprite(mapBackgroundTexture);

		// Create the BG Map
		this.map = new Map();
		const map = this.map.create();

		const mapContainer = new PIXI.Container();
		mapContainer.addChild(mapBackground, map, mapMask);
		map.mask = mapMask;

		this.addChild(mapContainer);

		// Create the SceneContainer
		this.sceneContainer = new PIXI.Container();
		this.addChild(this.sceneContainer);

		// Create the Border
		this.border = new Border(this);
		const borderContainer = this.border.create();
		this.addChild(borderContainer);

		// Create the SceneNavigator
		this.sceneNavigator = new SceneNavigator(this);
		let sceneNavigatorContainer = this.sceneNavigator.createNavigation();
		this.addChild(sceneNavigatorContainer);

		// Create the SceneManager to handle new scene requests
		this.sceneManager = new SceneManager(this.sceneNavigator, this);
		this.resize();
	};

	setStyle = (): void => {
		const style = this.app.renderer.view.style;
		style.position = 'absolute';
		style.top = '0px';
		style.left = '0px';
	};

	addChild = (container: PIXI.Container) => {
		this.app.stage.addChild(container);
	};

	init = () => {
		// Allow the stage to listen for user events
		this.app.stage.interactive = true;
		// Listen for resize events
		window.addEventListener('resize', this.resize);

		// Mouse coordinates, uncomment for debugging location and position
		// this.app.stage.on('pointermove', (event) => {
		// 	const global = event.data.global;
		// 	console.log(global.x, global.y);
		// });

		this.setStyle();

		// Append render type to document title
		if (PIXI.utils.isWebGLSupported()) {
			document.title += ` (WebGL [${this.resolution}])`;
		} else {
			document.title += ' (Canvas)';
		}

		// TODO: Move this to its own SceneManager class
		// Create the container for the scenes
		// create the container for the scenes
		// const sceneContainer = new PIXI.Container();
		// const sceneIndex = 1;
		// this.app.stage.addChildAt(sceneContainer, sceneIndex);

		// TODO: Move the Scenes to their own config file
		// this.engine = new Engine(this.app, [
		// 	{
		// 		index: 0,
		// 		name: 'clockwise',
		// 		exhibitScene: new ClockwiseScene(),
		// 	},
		// 	{
		// 		index: 2,
		// 		name: 'counterClockwise',
		// 		exhibitScene: new CounterClockwiseScene(),
		// 	},
		// ]);
		// Kick off the animation
		// this.app.ticker.add((delta) => {
		// 	//this.engine.update(delta);
		// });

		// We use GSAP's ticker instead
		this.app.ticker.stop();
		// Now, we use 'tick' from gsap
		gsap.ticker.add(() => {
			this.app.ticker.update();
		});
	};

	resize = () => {
		console.log('resizing... ');
		// Find the ratio of the window relative to the target
		let ratio = Math.min(
			window.innerWidth / AppManager.logicalWidth,
			window.innerHeight / AppManager.logicalHeight
		);

		// Scale the view to best fill the most constrained dimension
		this.app.stage.scale.x = this.app.stage.scale.y = ratio;

		// Update the renderer dimensions
		this.app.renderer.resize(
			Math.ceil(AppManager.logicalWidth * ratio),
			Math.ceil(AppManager.logicalHeight * ratio)
		);

		// Center the canvas within the available browser window
		// (can also be done with CSS)
		// Round pixels to ensure crisp pixels
		this.app.renderer.view.style.top =
			Math.round(window.innerHeight / 2 - this.app.stage.height / 2) + 'px';
		this.app.renderer.view.style.left =
			Math.round(window.innerWidth / 2 - this.app.stage.width / 2) + 'px';

		// Display window stats for debug
		// prettier-ignore
		console.log(
			`
			Resize
				Window-inner: ${window.innerWidth} x ${window.innerHeight}, pixel-ratio: ${this.resolution}
				Renderer: ${this.app.renderer.width} x ${this.app.renderer.height}, resolution depth: ${this.app.renderer.resolution}
				Scale: ${this.app.stage.scale.x}, ${this.app.stage.scale.y}
			`
		);
	};
}
